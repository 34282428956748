<template>
    <div class="d-relative">
        <button
            class="btn btn-primary btn-sm position-absolute"
            style="right: 0; z-index: 2"
            @click="saveSettings"
        >
            Simpan
        </button>
        <h5 class="mb-3">Payment Expired Settings</h5>
        <!-- <template  v-for="(item, index) in list">
   <div class="row mb-3" :key="index" v-if="!item.hasChild">
       <div class="col-md-4">
           {{item.name}}
       </div>
       <div class="col-md-8">
           <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" :id="`default-${index}`" :name="`method-payment-${index}`" class="custom-control-input" value="true" v-model="item.default"  >
                <label class="custom-control-label" :for="`default-${index}`">Default</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" :id="`custom-${index}`" :name="`method-payment-${index}`" class="custom-control-input" value="false" v-model="item.default"  >
                <label class="custom-control-label" :for="`custom-${index}`">Custom</label>
                <template v-if="item.default == 'false'">
                    <input class="mx-4" type="text" style="width: 40px; padding: 2px; border: 1px solid black;text-align:center;">
                    <select name="" id="">
                        <option value="" v-for="time in timeMethodPayment" :key="time">{{time}}</option>
                    </select>
                </template>
            </div>
       </div>
    </div>  
    </template>   -->
        <div v-if="!loadingAPI">
            <div class="row mb-3" v-for="(item, index) in list" :key="index">
                <div class="col-md-4">
                    {{ item.paymentMethod.name }}
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-6">
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    :id="`default-${index}`"
                                    :name="`method-payment-${index}`"
                                    class="custom-control-input"
                                    value="true"
                                    v-model="list[index].default"
                                />
                                <label
                                    class="custom-control-label mr-2"
                                    :for="`default-${index}`"
                                    >Default</label
                                >
                                <input
                                    :value="
                                        `${item.paymentMethod.expiry} ${
                                            item.paymentMethod.expiryUnit
                                        }`
                                    "
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div
                                class="custom-control custom-radio custom-control-inline"
                            >
                                <input
                                    type="radio"
                                    :id="`custom-${index}`"
                                    :name="`method-payment-${index}`"
                                    class="custom-control-input"
                                    value="false"
                                    v-model="list[index].default"
                                />
                                <label
                                    class="custom-control-label"
                                    :for="`custom-${index}`"
                                    >Custom</label
                                >
                                <template
                                    v-if="
                                        list[index].default == 'false' ||
                                            !list[index].default
                                    "
                                >
                                    <input
                                        class="mx-4"
                                        type="text"
                                        style="
                      width: 40px;
                      padding: 2px;
                      border: 1px solid black;
                      text-align: center;
                    "
                                        v-model="list[index].expiry"
                                    />
                                    <select
                                        name=""
                                        id=""
                                        v-model="list[index].expiryUnit"
                                    >
                                        <option
                                            :value="time"
                                            v-for="time in timeMethodPayment"
                                            :key="time"
                                        >
                                            {{ time }}
                                        </option>
                                    </select>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="m-auto p-5 d-flex flex-row h-100">
            <div
                class="
          kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary
          my-auto
        "
            ></div>
            <h5 class="my-auto pl-5">Loading ...</h5>
        </div>
        <hr />
        <!-- Payment -->
        <h5 class="mb-3">Payment Config</h5>
        <div v-if="!isLoadingConfig">
            <div
                class="row mb-3"
                v-for="(item, index) in configPayment"
                :key="index"
            >
                <div class="col-md-3">
                    <label for="" class="text-word-break">{{ item.key }}</label>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-6" v-if="item.key !== 'PAYMENT_WEBHOOK'">
                            <input
                                    type="radio"
                                    :id="`default-config-${index}`"
                                    :name="`payment-config-${index}`"
                                    value="1"
                                    v-model="configPayment[index].selectRadio"
                                />
                            <textarea disabled class="form-control" rows="3" :value="configPayment[index].valueDefault"></textarea>
                        </div>
                        <div :class="item.key === 'PAYMENT_WEBHOOK' ? 'col-12': 'col-6'">
                           <input
                                    v-if="item.key !== 'PAYMENT_WEBHOOK'"
                                    type="radio"
                                    :id="`config-${index}`"
                                    :name="`payment-config-${index}`"
                                    value="2"
                                    v-model="configPayment[index].selectRadio"
                                />
                            <textarea
                                class="form-control"
                                rows="3"
                                v-model="configPayment[index].value"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-md-1">
                    <button
                        class="btn btn-primary btn-sm "
                        :class="item.key !== 'PAYMENT_WEBHOOK' ? 'mt-4': ''"
                        @click="submitConfigPayment(index, item.key)"
                    >
                        Save
                    </button>
                    <br>
                    <router-link
                        :to="{
                            name: 'ShowWebhookHistory',
                            params: {
                                id: $route.params.id
                            }
                        }"
                     tag="button" class="btn btn-outline-success btn-sm mt-1" v-if="item.key === 'PAYMENT_WEBHOOK'">
                        History
                    </router-link>
                    <!-- <button
                        class="btn btn-danger btn-sm"
                        @click="deleteMethodConfig(index)"
                    >
                        Delete
                    </button> -->
                </div>
            </div>
            <!-- <div class="row float-left">
             <hr />
            <button v-if="!isAddConfig" class="btn btn-primary" @click="tambahConfig">Tambah Config</button>
                <div class="col-md-12" v-else>
                    <div class="form-group">
                        <label for="">Key</label>
                        <input type="text" class="form-control" v-model="newConfig.key">
                    </div>
                    <div class="form-group">
                        <label for="">Value</label>
                        <textarea class="form-control" rows="5" v-model="newConfig.value"/>
                    </div>
                    <br />
                    <button class="btn btn-primary" @click="addConfigNew()">Tambah Config</button>
                </div>
        </div> -->
        </div>
        <div v-else class="m-auto p-5 d-flex flex-row h-100">
            <div
                class="
          kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary
          my-auto
        "
            ></div>
            <h5 class="my-auto pl-5">Loading ...</h5>
        </div>
        <!-- <div class="row mt-3">
        <div class="col-md-3">

        <label for="" class="pt-2">Webhook Channels</label>
        </div>
        <div class="col-md-9">
            <input type="text" class="form-control"> 
        </div>
    </div>    -->
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    data() {
        return {
            list: [],
            timeMethodPayment: ["DAY", "HOUR", "MINUTE"],
            isAddConfig: false,
            newConfig: {
                key: "",
                value: ""
            }
        };
    },
    computed: {
        ...mapState("PaymentStore", {
            loadingAPI: state => state.loadingAPI,
            listPaymentMethodChannel: state => state.listPaymentMethodChannel,
            listPaymentConfig: state => state.listPaymentConfig,
            isLoadingConfig: state => state.isLoadingConfig,
            configPayment: state => state.configPaymentClient
        })
    },
    async mounted() {
        this.getPaymentConfig(this.$route.params.id);
        await this.getPaymentConfigGlobal()
        await this.getMethodPaymentClient(this.$route.params.id);
        const list = JSON.stringify(this.listPaymentMethodChannel);
        this.list = JSON.parse(list);
        this.list = this.list.map(item => {
            return { ...item, default: item.expiry ? false : true };
        });
        this.setConfigPayment()
    },
    watch: {
        // listPaymentConfig(data) {
        //     const config = JSON.stringify(data);
        //     this.configPayment = JSON.parse(config);
        // }
    },
    methods: {
        ...mapActions({
            getMethodPaymentClient:
                "PaymentStore/GET_PAYMENT_METHOD_BY_CHANNEL",
            getPaymentConfig: "PaymentStore/GET_PAYMENT_CONFIG_CLIENT",
            postPaymentConfig: "PaymentStore/POST_PAYMENT_CONFIG",
            deletePaymentConfig: "PaymentStore/DELETE_PAYMENT_CONFIG",
            postPaymentExpired: "PaymentStore/POST_POSITION_PAYMENT",
            getPaymentConfigGlobal: "PaymentStore/GET_CONFIG_PAYMENT_GLOBAL",
            setConfigPayment: 'PaymentStore/SET_PAYMENT_CLIENT_MERGED_ARRAY'
        }),
        tambahConfig() {
            this.isAddConfig = true;
        },
        submitConfigPayment(index , type){
          if(this.configPayment[index].selectRadio == '1' && type != 'PAYMENT_WEBHOOK') {
            // set default || delete if have id custom
            if(this.configPayment[index].id) {
                this.deleteMethodConfig(index)
               
            } else {
              toastr.success('Berhasil Menyimpan Data')
            }
          } else {
            // set id if have
            this.saveMethodConfig(index)
          }
        },
        saveMethodConfig(index) {
            const data = this.configPayment[index];
            const payload = {
                // id: data.id,
                ...(data.id ? {id: data.id}: {}),
                key: data.key,
                userId: this.$route.params.id,
                value: data.value
            };
            swal({
                title: "Apakah Yakin?",
                text: "Merubah Payment Config",
                type: "info",
                showCancelButton: true,
                confirmButtonText: "OK"
            }).then(result => {
                if (result.value) {
                    this.postPaymentConfig({
                        payload,
                        id: this.$route.params.id
                    });
                }
            });
        },
        deleteMethodConfig(index) {
            const idConfig = this.configPayment[index].id;
            swal({
                title: "Apakah Yakin?",
                text: "Mengubah Ke settingan Default akan menghapus settingan custom ini",
                type: "info",
                showCancelButton: true,
                confirmButtonText: "OK"
            }).then(result => {
                if (result.value) {
                    this.deletePaymentConfig({
                        idConfig,
                        id: this.$route.params.id
                    });
                     toastr.success('Berhasil Menyimpan Data')
                }
            });
        },
        addConfigNew() {
            const payload = {
                key: this.newConfig.key,
                value: this.newConfig.value,
                userId: this.$route.params.id
            };
            swal({
                title: "Apakah Yakin?",
                text: "Menambah Payment Config",
                type: "info",
                showCancelButton: true,
                confirmButtonText: "OK"
            }).then(result => {
                if (result.value) {
                    this.postPaymentConfig({
                        payload,
                        id: this.$route.params.id
                    });
                    this.newConfig.key = "";
                    this.newConfig.value = "";
                }
            });
        },
        saveSettings() {
            //   console.log(this.list)
            const payload = this.list.map(item => {
                const post = {};
                post.expiry =
                    item.default == true || item.default == "true"
                        ? 0
                        : Number(item.expiry);
                post.expiryUnit =
                    item.default == true || item.default == "true"
                        ? "DAY"
                        : item.expiryUnit;
                post.id = item.id;
                post.paymentMethodId = item.paymentMethod.id;
                post.requestMethod = "CREATE_OR_UPDATE";
                return post;
            });
            //   console.log(payload)
            const that = this;
            swal({
                title: "Apakah Yakin?",
                text: "Mengubah Settingan Payment ",
                type: "info",
                showCancelButton: true,
                confirmButtonText: "OK"
            }).then(result => {
                if (result.value) {
                    that.postPaymentExpired({
                        payload,
                        isLoading: true
                    });
                }
            });
        }
    }
};
</script>

<style>
</style>
