<template>
  <div>
    <v-server-table
      @loaded="onLoaded(0)"
      @loading="onLoaded(1)"
      :columns="columns"
      :options="options"
      ref="table"
    >
      <div slot="paymentTransactionStatus" slot-scope="{ row }">
        <span
          class="px-3 py-1 font-weight-bold"
          :style="{
            color: colors[row.paymentTransactionStatus],
            border: `1px solid ${colors[row.paymentTransactionStatus]}`,
          }"
        >
          {{ row.paymentTransactionStatus }}
        </span>
      </div>
      <div slot="action" slot-scope="{ row }">
            <router-link :to="{name: 'ShowDetailPayment',
                params: { id: row.userId, payment: row.id }}" >
                <i class="la la-external-link external-link"></i>
            </router-link>
        </div>
      <!-- <div v-if="isLoading" slot="afterBody">
        <div class="m-auto p-5 d-flex flex-row h-100">
          <div
            class="
              kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary
              my-auto
            "
          ></div>
          <h5 class="my-auto pl-5">Loading ...</h5>
        </div>
      </div> -->
    </v-server-table>

    <!-- 
  <v-client-table v-if="!loadingAPI" :data="listTransaksi" :columns="columns" :options="options" ref="table">
     
  </v-client-table>  -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { RepositoryFactory } from "../repositories/BaseRepositoryFactory";
const PaymentMethod = RepositoryFactory.get("PaymentMethod");

export default {
  computed: {
    ...mapState("PaymentStore", {
      listTransaksi: (state) => state.litTransactionChannels,
      loadingAPI: (state) => state.loadingAPI,
    }),
  },
  data() {
    const vx = this;
    return {
      isLoading: false,
      colors: {
        CREATED: "blue",
        UNDERPAYMENT: "grey",
        PAID: "green",
        EXPIRED: "black",
        CANCELLED: "red",
      },
      columns: [
        "id",
        "createdDate",
        "transactionOrderId",
        "grossAmount",
        "typePayment",
        "paymentTransactionStatus",
         "action"
      ],
      options: {
        filterByColumn: true,
        listColumns: {
          paymentTransactionStatus: [
            {
              id: "CREATED",
              text: "Created",
            },
            {
              id: "UNDERPAYMENT",
              text: "Underpayment",
            },
            {
              id: "PAID",
              text: "Paid",
            },
            {
              id: "EXPIRED",
              text: "Expired",
            },
            {
              id: "CANCELLED",
              text: "Cancel",
            },
          ],
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-up",
          down: "fa-sort-down ",
        },
        sortable: [
          "createdDate",
          "transactionOrderId",
          "grossAmount",
          "typePayment",
          "paymentTransactionStatus",
        ],
        headings: {
          id: "ID",
          createdDate: "Tanggal Dibuat",
          transactionOrderId: "Id Pesanan",
          grossAmount: "Total Pesanan",
          paymentTransactionStatus: "Status",
          typePayment: "Tipe Pembayaran",
           action: "Actions",
        },
        texts: {
          loading: "Loading...",
        },
        filterable: [
          "paymentTransactionStatus",
          "grossAmount",
          "createdDate",
          "typePayment",
          "transactionOrderId",
        ],
        dateColumns: ['createdDate' ],
        debounce: 500,
        templates: {
          typePayment(h, row) {
            if (row.payment && row.payment.paymentType) {
              return row.payment.paymentType;
            } else {
              return "";
            }
          },
          createdDate: function (e, row) {
            return moment(row.createdDate).format("DD MMM YYYY  HH:mm");
          },
        },
        requestFunction: function (data) {
          let params = {};
          vx.options.texts.loading = "Loading...";
          params.page = data.page - 1;
          params.size = data.limit;
          params["userId.equals"] = vx.$route.params.id;
          params.sort = "createdDate,desc"
          if (!_.isEmpty(data.query)) {
            params = {
              ...params,
              ...(data.query.paymentTransactionStatus
                ? {
                    "paymentTransactionStatus.in":
                      data.query.paymentTransactionStatus,
                  }
                : {}),
              ...(data.query.transactionOrderId
                ? {
                    "transactionOrderId.contains":
                      data.query.transactionOrderId,
                  }
                : {}),
                ...(data.query.typePayment
                ? {
                    "paymentType.contains":
                      data.query.typePayment,
                  }
                : {}),
              ...(data.query.id ? { "id.equals": data.query.id } : {}),
              ...(data.query.createdDate
                ? {
                      "createdDate.greaterOrEqualThan": `${moment(
                          data.query.createdDate.start
                      ).format("YYYY-MM-DD")}T00:00:00Z`,
                      "createdDate.lessOrEqualThan": `${moment(
                          data.query.createdDate.end
                      ).format("YYYY-MM-DD")}T23:59:00Z`
                  }
                : {}),
            };
            // params['transactionOrderId.contains'] = data.query.transactionOrderId
            // params['templateName.contains'] = `${data.query.templateName}`;
          }
          if (data.orderBy) {
              let orderBy = data.orderBy
              if(data.orderBy == 'typePayment'){
                  orderBy = 'payments.paymentType'
              }
            params.sort = `${orderBy},${
              data.ascending == "1" ? "desc" : "asc"
            }`;
            // params.sort = `${vx.checkParams(data.orderBy)},${data.ascending == "1" ? 'desc': 'asc'}`
          }
          return PaymentMethod.fetch(params).catch(function (e) {
            vx.isLoading = false;
            toastr.error("Error Get Data");
            vx.options.texts.loading = "Data tidak ditemukan";
          });
        },
        responseAdapter: (resp) => {
          return {
            data: resp.data,
            count: resp.headers["x-total-count"]
              ? resp.headers["x-total-count"]
              : resp.headers["Content-Length"]
              ? resp.headers["Content-Length"]
              : resp.data.length,
          };
        },
      },
    };
  },
  methods: {
    ...mapActions({
      getDataTransaksi: "PaymentStore/GET_TRANSACTION_CHANNELS",
    }),
    onLoaded: function (t) {
      t == 0 ? (this.isLoading = false) : (this.isLoading = true);
    },
  },
  mounted() {
    if (this.$route.params.id) {
      //  this.getDataTransaksi(this.$route.params.id)
    }
  },
};
</script>

<style>
</style>