<template>
  <div>
    <base-header :base-title="headTitle"></base-header>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitleName"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
               
              <router-link
                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                :to="{ name: 'ManajementPayment' }"
              >
                <i class="flaticon2-back"></i>
                {{ $t("button.previous") }}
              </router-link>
              <router-link
                class="btn btn-primary btn-bold btn-upper btn-font-sm mr-2"
                :to="{ name: 'create-payment-snap', params: {id: $route.params.id} }"
              >
                <!-- <i class="flaticon2-back"></i> -->
                 Create Snap 
              </router-link>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <!-- content -->
          <ul
            class="nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-bold"
            role="tablist"
          >
            <li class="nav-item" @click="routerSet(1)">
              <a
                class="nav-link"
                :class="isShowTab == 1 ? 'active show' : ''"
                data-toggle="tab"
                href="#transaksi"
                role="tab"
                aria-selected="false"
              >
                Transaksi
              </a>
            </li>
            <li class="nav-item" @click="routerSet(2)">
              <a
                class="nav-link"
                :class="isShowTab == 2 ? 'show active' : ''"
                data-toggle="tab"
                href="#pembayaran"
                role="tab"
                aria-selected="false"
              >
                Jenis Pembayaran
              </a>
            </li>
            <li class="nav-item" @click="routerSet(3)">
              <a
                class="nav-link"
                :class="isShowTab == 3 ? 'show active' : ''"
                data-toggle="tab"
                href="#settings"
                role="tab"
                aria-selected="false"
              >
                Pengaturan
              </a>
            </li>
          </ul>
          <div
            class="tab-pane fade"
            :class="isShowTab == 1 ? 'show active' : ''"
            id="transaksi"
            role="tabpanel"
            v-if="isShowTab == 1"
          >
            <list-transaksi />
          </div>
          <div
            class="tab-pane fade"
            :class="isShowTab == 2 ? 'show active' : ''"
            id="pembayaran"
            role="tabpanel"
            v-if="isShowTab == 2"
          >
            <list-method-payment />
          </div>
          <div
            class="tab-pane fade"
            :class="isShowTab == 3 ? 'show active' : ''"
            id="settings"
            role="tabpanel"
            v-if="isShowTab == 3"
          >
            <setting-payment />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "./../../../components/_base/BaseTitle";
import BaseHeader from "./../../../components/_base/BaseHeader";
import ListTransaksi from "../../components/listTransaksi.vue";
import listMethodPayment from "../../components/ListPaymentMethod.vue";
import SettingPayment from "../../components/SettingPayment.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    BaseTitle,
    BaseHeader,
    ListTransaksi,
    listMethodPayment,
    SettingPayment,
  },
  data() {
    return {
      headTitleName: "Payment Client",
      headTitle: "Manajemen Pembayaran Klien",
      isShowTab: 0,
    };
  },
  computed: {
    ...mapState("PaymentStore", {
      detail: (state) => state.detailTransaksiClient,
    }),
  },
  async mounted() {
    // console.log(this.$route)
    if (this.$route.query.tabs) {
      this.isShowTab = this.$route.query.tabs;
    } else {
        this.isShowTab = 1
    }
    await this.getDetail(this.$route.params.id);
    this.headTitleName = this.detail.userName;
  },
  watch: {
    "$route.query.tabs": function (val) {
      this.isShowTab = val;
    },
  },
  methods: {
    routerSet(tab) {
      this.$router.replace({
        name: "manajement-payment-id",
        params: { id: this.$route.params.id },
        query: { tabs: tab },
      });
    },
    ...mapActions({
      getDetail: "PaymentStore/GET_ALL_TRANSACTION_USER_BY_ID",
    }),
  },
};
</script>

<style>
</style>