<template>
  <div class="d-relative">
    <load-spinner v-if="isLoadingConfig"></load-spinner>
    <button
      class="btn btn-primary btn-sm position-absolute"
      style="right: 0; z-index: 2"
      @click="save"
    >
      Simpan
    </button>
    <div class="row justify-content-between" v-if="!loadingAPI">
      <div class="col-12">
        <h5>List Payment Method Channel</h5>

        <!-- <ul style="list-style: none"> -->
        <draggable tag="ul"  :list="listAllShow" :group="`parent`" style="list-style: none;" @change="dragItem">
          <li v-for="(checkbox, index) in listAllShow" :key="index">
            <input
              type="checkbox"
              :id="`check-${checkbox.id}`"
              class="check-status"
              @change="setClickParent($event)"
            />
            <label :for="`check-${checkbox.id}`" class="ml-2">
              <img
                :src="SetImage(checkbox.icon)"
                @error="$event.target.src = defaultImage"
              />
              <span class="ml-2">{{ checkbox.name }}</span>
            </label>
            <!-- <ul v-if="checkbox.children" style="list-style: none"> -->
              <template v-if="checkbox.children"><draggable tag="ul" :list="checkbox.children" :group="`children-${index}`" style="list-style: none;" @change="dragItem">
              <li
                v-for="(checkbox2, index2) in checkbox.children"
                :key="index2"
              >
                <input
                  type="checkbox"
                  :id="`check-${checkbox2.id}`"
                  class="check-status"
                  @change="setClickChildren($event)"
                />
                <label :for="`check-${checkbox2.id}`" class="ml-2">
                  <img
                    :src="SetImage(checkbox2.icon)"
                    @error="$event.target.src = defaultImage"
                  />
                  <span class="ml-2">{{ checkbox2.name }}</span>
                </label>
              </li>
              </draggable></template>
            <!-- </ul> -->
          </li>
          </draggable>
        <!-- </ul> -->

        <!-- <div class="mb-3" v-for="(checkbox, index) in listAllShow" :key="index">
            <input type="checkbox" :id="`check-${checkbox.id}`" @change="setTime(checkbox.id)">
            <label :for="`check-${checkbox.id}`" class="ml-2">
                <img :src="SetImage(checkbox.icon)" width="45"  @error="$event.target.src=defaultImage"> 
                <span class="ml-2">{{checkbox.name}}</span>
            </label>
            <template v-if="checkbox.children">
                <div class="my-2 pl-5" v-for="(checkbox2, index2) in checkbox.children" :key="index2">
                    <input type="checkbox" :id="`check-${checkbox2.id}`" @change="setTimeParent(checkbox2)">
                    <label :for="`check-${checkbox2.id}`" class="ml-2">
                        <img :src="SetImage(checkbox2.icon)" width="45"  @error="$event.target.src=defaultImage"> 
                        <span class="ml-2">{{checkbox2.name}}</span>
                    </label>
                </div>

            </template>
        </div> -->

        <!-- <draggable class="list-group border py-2" :list="listShow" group="people" >
            <div v-for="item in listShow" :key="item.id" class="mb-2">
                <card-payment :base64="item.icon" :name="item.name" :description="item.description" />
            </div>
        </draggable> -->
      </div>
    </div>
    <div v-else class="m-auto p-5 d-flex flex-row h-100">
      <div
        class="
          kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary
          my-auto
        "
      ></div>
      <h5 class="my-auto pl-5">Loading ...</h5>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import draggable from 'vuedraggable'
import LoadSpinner from "./../../components/_general/LoadSpinner";
// import CardPayment from './cardPaymentMethod.vue'
// import noImage from "../assets/no-payment.png";
import noImage from '../assets/default-bank.svg'

export default {
  components: {
    LoadSpinner,
    draggable
    // CardPayment
  },
  data() {
    return {
      listShow: [],
      listAllShow: [],
      checked: [],
      defaultImage: noImage,
    };
  },
  computed: {
    ...mapState("PaymentStore", {
      paymentMethod: (state) => state.paymentMethod,
      listPaymentMethodChannel: (state) => state.listPaymentMethodChannel,
      AllPayment: (state) => state.listPaymentGlobal,
      loadingAPI: (state) => state.loadingAPI,
      isLoadingConfig: (state) => state.isLoadingConfig,
    }),
  },
  async mounted() {
    await this.getAllData();
    this.listAllShow = structuredClone(this.paymentMethod);
    await this.getPaymentMethodByChannel(this.$route.params.id);
    this.listShow = structuredClone(this.listPaymentMethodChannel).map(item => item.paymentMethod.id)
    setTimeout(()=> {
      this.dragItem()
    }, 500)
  },
  methods: {
    insertAndShift(arr, from, to) {
      let cutOut = arr.splice(from, 1) [0];
      arr.splice(to, 0, cutOut);
    },
    setPosition(groupList, list) {
      return new Promise((resolve) => {
      let letgroupList =  structuredClone(groupList)
      let parentSame = -1
      let childSame = -1
      let childIdName = ''
      list.forEach(item => {
        const parent = this.AllPayment.findIndex(child => child.parentId && item == child.id)
        if(parent >= 0) {
           const positionParent = letgroupList.findIndex(listParent => listParent.id == this.AllPayment[parent].parentId)
          if(childIdName != this.AllPayment[parent].parentId) {
            childIdName = this.AllPayment[parent].parentId
            childSame = -1
            parentSame++
            this.insertAndShift(letgroupList, positionParent, parentSame)
          }
          childSame++
          const positionChild = groupList[parentSame].children.findIndex(child => child.id == item)
          this.insertAndShift(letgroupList[parentSame].children, positionChild, childSame)

        } else {
          parentSame++
          const positionParent = letgroupList.findIndex(listParent => listParent.id == item)
          this.insertAndShift(letgroupList, positionParent, parentSame)
        }

      })
      resolve(letgroupList)
      })
    },
    checkSibling(id) {
      // const checkData = this.AllPayment
      const index = this.AllPayment.findIndex(item => item.id == id)
      const isChildren = this.AllPayment[index].parentId ? true : false
      if (isChildren) {
        let someInCheck = 0
        const indexParent = this.listAllShow.findIndex(item => item.id == this.AllPayment[index].parentId)
        const allChildren = this.listAllShow[indexParent].children
        allChildren.forEach(item => {
          const indexChild = this.listShow.findIndex(child => child == item.id)
          if(indexChild >= 0) {
            someInCheck++
          }
        })
        if (someInCheck == 0) {
           $(`#check-${this.listAllShow[indexParent].id}`).prop('indeterminate', false)
           $(`#check-${this.listAllShow[indexParent].id}`).prop('checked', false)
        } else if (someInCheck == allChildren.length) {
          $(`#check-${this.listAllShow[indexParent].id}`).prop('indeterminate', false)
          $(`#check-${this.listAllShow[indexParent].id}`).prop('checked', true)
        } else {
          $(`#check-${this.listAllShow[indexParent].id}`).prop('indeterminate', true)
        }
      }
     
    },
    getIdCheck(name) {
      return name.split('-')[1]
    },
    setClickChildren(evt) {
       const id = this.getIdCheck(evt.target.id)
       const index = this.listShow.findIndex(item => item == id )
       if(evt.target.checked) {
         if(index < 0) {
          this.listShow.push(id)
        }
       } else {
        if(index >= 0) {
          this.listShow.splice(index, 1)
        }
       }
       this.checkSibling(id)
    },
    setClickParent(evt) {
      const id = this.getIdCheck(evt.target.id)
      const children = this.listAllShow.filter(item => item.id == id)
      if(children[0].children ) {
        if(evt.target.checked) {
          children[0].children.forEach(item => {
            $(`#check-${item.id}`).prop('checked', true)
          })
        } else {
          children[0].children.forEach(item => {
            $(`#check-${item.id}`).prop('checked', false)
          })
        }
      } else {
          const index = this.listShow.findIndex(item => item == id )
          if(evt.target.checked) {
            if(index < 0) {
              this.listShow.push(id)
            }
          } else {
            if(index >= 0) {
              this.listShow.splice(index, 1)
            }
          }
      }
    },
    dragItem() {
      // hapus terlebih dahulu
       const input = $('input')
       for(let i = 0 ; i < input.length; i++) {
        $(`#${input[i].id}`).prop('indeterminate', false)
        $(`#${input[i].id}`).prop('checked', false)
       }
        this.listShow.forEach(item => {
          $(`#check-${item}`).click()
        })
    },
    ...mapActions({
      getPaymentMethodByChannel: "PaymentStore/GET_PAYMENT_METHOD_BY_CHANNEL",
      getAllData: "PaymentStore/GET_ALL_PAYMENT_METHODS",
      postPositionPayment: "PaymentStore/POST_POSITION_PAYMENT",
    }),
    save() {
      const allCheck = $(".check-status");
      let idCheck = [];
      for (let i = 0; i < allCheck.length; i++) {
        const id = allCheck[i].getAttribute("id");
        if ($(`#${id}`).is(":checked")) {
          idCheck.push(id.replace("check-", ""));
        }
      }
      swal({
        title: "Apakah Yakin?",
        text: "Mengubah Payment Method pada Client",
        type: "info",
        showCancelButton: true,
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          //  this.save()
          this.checkNotChild(idCheck);
        }
      });

      //   console.log(this.checked)
    },
    checkNotChild(item) {
      this.checked = [];
      for (let i = 0; i < item.length; i++) {
        const getInChannel = this.listPaymentMethodChannel.findIndex(
          (items) => items.paymentMethod.id == item[i]
        );
        if (getInChannel >= 0) {
          const body = {
            id: this.listPaymentMethodChannel[getInChannel].id,
            paymentMethodId:
              this.listPaymentMethodChannel[getInChannel].paymentMethod.id,
            requestMethod: "CREATE_OR_UPDATE",
            userId: this.$route.params.id,
            position: this.checked.length,
          };
          this.checked.push(body);
        } else {
          const getItem = this.AllPayment.findIndex(
            (items) => items.id == item[i]
          );
          // if (!this.AllPayment[getItem].hasChild) {
            const body = {
              paymentMethodId: this.AllPayment[getItem].id,
              requestMethod: "CREATE_OR_UPDATE",
              userId: this.$route.params.id,
              position: this.checked.length,
            };
            this.checked.push(body);
          // }
        }
      }
      this.checkDeleteItem(item);
    },
    checkDeleteItem(item) {
      for (let i = 0; i < this.listPaymentMethodChannel.length; i++) {
        const findId = item.findIndex(
          (sets) => sets == this.listPaymentMethodChannel[i].paymentMethod.id
        );
        //  console.log('after', findId)
        if (findId < 0) {
          //  console.log('2---', findId, this.listPaymentMethodChannel[i])
          const body = {
            id: this.listPaymentMethodChannel[i].id,
            paymentMethodId: this.listPaymentMethodChannel[i].paymentMethod.id,
            requestMethod: "DELETE",
            userId: this.$route.params.id,
          };
          this.checked.push(body);
        }
      }
      this.postPositionPayment({ payload: this.checked, isLoading: false });
      //   console.log(this.checked)
    },
    SetImage(icon) {
      // return "data:image/png;base64," + icon;
      return "data:image/svg+xml;base64," + icon;
    },
  },
};
</script>